@import 'styles/layers.scss';

@layer uikit {
  .range {
    -webkit-appearance: none;
    appearance: none;
    height: 6px;
    border-radius: 4px;
    background: linear-gradient(to right, var(--grey2) 0%, var(--black10) 0%);
    margin-right: 8px;
  }
  
  .range::-webkit-slider-runnable-track {
    background-color: var(--black10);
    border-radius: 4px;
    height: 6px;
  }
  
  .range::-moz-range-track {
    background-color: var(--black10);
    border-radius: 4px;
    height: 6px;
  }
  
  .range::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    background-color: var(--white);
    width: 16px;
    height: 10px;
    margin-top: -2px;
    border-radius: 80px;
  }
  
  .range::-moz-range-thumb {
    background-color: var(--white);
    width: 16px;
    height: 10px;
    margin-top: -2px;
    border-radius: 80px;
  }
  
  .input {
    width: 105px;
    border-radius: 6px;
  }
  
  .input-wrap {
    border: 1px solid var(--black11);
    border-radius: 6px;
  }
  
  .label-has-input {
    margin-bottom: 6px;
  }
  
  .label-no-input {
    margin-bottom: 19px;
  }
  
  .description {
    font-size: 12px;
    opacity: 0.3;
  }
}