@import 'styles/layers.scss';

@layer component {
  .content{
    width: 100%;
  }

  .empty {
    flex: 0;
    width: 420px;
  }
}
