@import 'styles/layers.scss';

@layer uikit {
  .card {
    width: 100%;
    font-weight: 400;
    gap: 15px;
  }
  
  .group {
    width: 100%;
  }
  
  .groupLine {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    grid-gap: 20px;
  }
  
  .propsLine {
    gap: 20px;
  }
  
  .prop {
    white-space: nowrap;
  }
  
  .headerLine {
    font-size: 20px;
    line-height: 30px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  
  .title {
    opacity: 0.3;
  }
  
  .text {
    margin-left: 6px;
  }
  
  .footerText {
    line-height: 22px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  
    @supports (-webkit-line-clamp: 2) {
      height: 42px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: initial;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
  
  .disabled {
    .headerLine, .price, .provider, .value {
      color: var(--grey22);
    }
    .footerText {
      opacity: 0.5;
    }
  }
}