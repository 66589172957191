@import 'styles/layers.scss';

@layer component {
  .counter-title {
    opacity: 0.3;
  }

  .counter {
    margin-bottom: 26.5px;
  }
}