@import 'styles/layers.scss';

@layer component {
  .content{
    width: 100%;
  }

  .list {
    width: 420px;
  }
}
